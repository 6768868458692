

















































































import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import {AuthBackendAPI} from '@/logic/authentication/AuthBackendAPI';
import {
    ArkStatsBackendClient, BuildingDto,
    DinoDto, LocationDto,
    PurgeFlagDto,
    ResourceListDto
} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import {ServerDto} from '@/logic/ArkServerManagerClient';
import AsyncComputedProp from 'vue-async-computed-decorator';
import TribeName from '@/components/TribeName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {TribeName}
})
export default class PurgeFlags extends Vue {

    private servers: ServerDto[] = [];
    private server: number|null = null;

    private expandedBuilding: string|null = null;

    private PURGE_FLAG_RANGE = 15000;

    private error: string|null = null;

    private expandBuilding(building: BuildingDto): void {
        this.expandedBuilding = building.id;
    }

    async created(): Promise<void> {
        this.servers = (await ArkStatsBackendClient.getGenericObject<ResourceListDto<ServerDto>>("server", { fields: ['id', 'name' ], orderBy: 'id'})).data;
    }

    private orderByCount = (a: BuildingDto, b: BuildingDto): number => {
        if(a.count === b.count) {
            return a.lastInAllyRange < b.lastInAllyRange ? 1 : -1;
        }
        return (a.count < b.count) ? 1 : -1;
    };

    private scrollMeTo(refName: string): void {
        Vue.nextTick().then(() => {
            const el = (this.$refs[refName] as any)[0];
            console.log(el);
            el.scrollIntoView({
                behavior: 'smooth',
                alignToTop: true,
                block: 'center'
            });
        })
    }

    @AsyncComputedProp()
    public async expandedBuildingStructures(): Promise<BuildingDto | null> {
        if (!this.expandedBuilding) {
            return null;
        }
        const building = (await ArkStatsBackendClient.getBuildings({
            filter: `id==${this.expandedBuilding}`,
            fields: ['countByType'],
            limit: 1
        })).data;

        if(building.length > 0) {
            return building[0];
        }
        return null;
    }

    @AsyncComputedProp()
    public async dinosToPurge(): Promise<{serverId: number, dinos: DinoDto[], buildings: BuildingDto[], purgeFlags: PurgeFlagDto[], coordBounds: {minX: number, maxX: number, minY: number, maxY: number, xFactor: number, yFactor: number}}> {
        if (!this.server) {
            return {serverId: 0, dinos: [], buildings: [], purgeFlags: [], coordBounds: this.coordBounds([], [], [])};
        }
        const serverId = this.server;
        try {
            const dinos = (await ArkStatsBackendClient.getDinos({
                filter: `serverId==${serverId};died==null`,
                fields: ['location', 'owner.tribeId', 'owner.playerId', 'serverId', 'id1'],
                orderBy: 'location.x',
                limit: 50000
            })).data;
            const purgeFlags = (await ArkStatsBackendClient.getPurgeFlags({
                filter: `serverId==${serverId}`,
                fields: ['ownerId', 'location'],
                orderBy: 'location.x',
                limit: 50000
            })).data;
            const buildings = (await ArkStatsBackendClient.getBuildings({
                filter: `serverId==${serverId}`,
                fields: ['ownerId', 'minLocation', 'maxLocation', 'count', 'serverId', 'created', 'lastInAllyRange'],
                limit: 50000
            })).data;
            const purgeFlagsByOwnerId: Map<number, PurgeFlagDto[]> = new Map<number, PurgeFlagDto[]>();
            purgeFlags.forEach(purgeFlag => {
                if(!purgeFlagsByOwnerId.has(purgeFlag.ownerId)) {
                    purgeFlagsByOwnerId.set(purgeFlag.ownerId, []);
                }
                purgeFlagsByOwnerId.get(purgeFlag.ownerId)?.push(purgeFlag);
            });
            const dinosToPurge = dinos.filter(dino => {
                const ownerId = Number(dino?.owner?.tribeId != null ? dino?.owner?.tribeId : dino?.owner?.playerId);
                const purgeFlags = purgeFlagsByOwnerId.get(ownerId);
                let notCoveredByFlag = true;
                purgeFlags?.forEach(purgeFlag => {
                    const purgeFlagLocation = purgeFlag.location!;
                    if(this.calculateDistance(purgeFlagLocation, dino.location!) < this.PURGE_FLAG_RANGE) {
                        notCoveredByFlag = false;
                    }
                });
                return notCoveredByFlag;
            });

            const buildingsToPurge = buildings.filter(building => {
                const ownerId = Number(building.ownerId);
                const purgeFlags = purgeFlagsByOwnerId.get(ownerId);
                let notCoveredByFlag = true;
                purgeFlags?.forEach(purgeFlag => {
                    const purgeFlagLocation = purgeFlag.location!;
                    if(this.calculateDistance(purgeFlagLocation, this.averageLocation(building.minLocation, building.maxLocation)) < this.PURGE_FLAG_RANGE ||
                        this.calculateDistance(purgeFlagLocation, building.minLocation) < this.PURGE_FLAG_RANGE ||
                        this.calculateDistance(purgeFlagLocation, building.maxLocation) < this.PURGE_FLAG_RANGE) {

                        notCoveredByFlag = false;
                    }
                });
                return notCoveredByFlag;
            });

            return {
                serverId: serverId,
                dinos: dinosToPurge,
                buildings: buildingsToPurge.sort(this.orderByCount),
                coordBounds: this.coordBounds(dinos, purgeFlags, buildings),
                purgeFlags: purgeFlags
            }
        } catch(ex) {
            const errorReason = ex?.response?.data?.error?.reason;
            if(!errorReason) {
                this.error = 'An unknown error occured';
            } else {
                this.error = errorReason;
            }

            console.log(ex.response);
            return {serverId: serverId, dinos: [], buildings: [], coordBounds: this.coordBounds([], [], []), purgeFlags: []};
        }
    }

    public openDino(dino: DinoDto): void {
        window.open(`https://ark.domination-gaming.com/server/${dino.serverId}/dino/${dino.id1}`);
    }

    public getStyle(location: LocationDto, coordBounds: {minX: number, maxX: number, minY: number, maxY: number, xFactor: number, yFactor: number}) {
        const result = `margin-top: ${(location.y - coordBounds.minY) * coordBounds.yFactor - 2.5}px;` +
            `margin-left: ${(location.x - coordBounds.minX) * coordBounds.xFactor - 2.5}px;`;
        return result;
    }

    public getPurgeFlagStyle(location: LocationDto, coordBounds: {minX: number, maxX: number, minY: number, maxY: number, xFactor: number, yFactor: number}) {
        const result =
            `margin-top: ${(location.y - coordBounds.minY - this.PURGE_FLAG_RANGE) * coordBounds.yFactor}px;` +
            `margin-left: ${(location.x - coordBounds.minX - this.PURGE_FLAG_RANGE) * coordBounds.xFactor}px;` +
            `width: ${this.PURGE_FLAG_RANGE * 2 * coordBounds.xFactor}px;` +
            `height: ${this.PURGE_FLAG_RANGE * 2 * coordBounds.yFactor}px`;
        return result;
    }

    public getBuildingStyle(min: LocationDto, max: LocationDto, coordBounds: {minX: number, maxX: number, minY: number, maxY: number, xFactor: number, yFactor: number}) {
        const result =
            `margin-left: ${(min.x - coordBounds.minX) * coordBounds.xFactor}px;` +
            `margin-top: ${(min.y - coordBounds.minY) * coordBounds.yFactor}px;` +
            `width: ${(max.x - min.x) * coordBounds.xFactor}px;` +
            `height: ${(max.y - min.y) * coordBounds.yFactor}px`;
        return result;
    }

    public tpCommand(location: LocationDto): string {
        return `SetPlayerPos ${Math.round(location.x)} ${Math.round(location.y)} ${Math.round(location.z)}`
    }

    public coordBounds(dinos: DinoDto[], purgeFlags: PurgeFlagDto[], buildings: BuildingDto[]): {minX: number, maxX: number, minY: number, maxY: number, xFactor: number, yFactor: number} {
        let minX = 0;
        let maxX = 0;
        let minY = 0;
        let maxY = 0;
        let locations: LocationDto[] = dinos.map(dino => dino.location)
            .concat(purgeFlags.map(purgeFlag => purgeFlag.location))
            .concat(buildings.map(building => building.minLocation))
            .concat(buildings.map(building => building.maxLocation));

        locations.forEach(location => {
            if(location.x && location.x < minX) {
                minX = location.x;
            }
            if(location.x && location.x > maxX) {
                maxX = location.x;
            }
            if(location.y && location.y < minY) {
                minY = location.y;
            }
            if(location.y && location.y > maxY) {
                maxY = location.y;
            }
        });
        const xFactor = 512 / (maxX - minX);
        const yFactor = 512 / (maxY - minY);

        return { minX, maxX, minY, maxY, xFactor, yFactor }
    }

    calculateDistance(location1: LocationDto, location2: LocationDto): number {
        return Math.sqrt(Math.pow(Math.abs(location1.x - location2.x), 2) +
            Math.pow(Math.abs(location1.y - location2.y), 2));
    }

    isAdmin(): boolean {
        return AuthBackendAPI.isAdmin(this.$store.state.currentUserState);
    }

    getAuthenticationLink(): string {
        return "https://auth.domination-gaming.com/oauth/discord?redirect_uri=" + encodeURIComponent(window.location.href);
    }

    private averageLocation(minLocation: LocationDto, maxLocation: LocationDto): LocationDto {
        return {
            x: (minLocation.x + maxLocation.x) / 2,
            y: (minLocation.y + maxLocation.y) / 2,
            z: (minLocation.z + maxLocation.z) / 2
        };
    }

    localDate(timestamp: string | Date): string {
        const dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
        return new Intl.DateTimeFormat('en-US', dateFormatOptions).format(new Date(timestamp));
    }
}
